import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo/logo.png'

const Navbar = class extends React.Component {

  componentDidMount() {
    // Get all "navbar-burger" elements
   const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    // Check if there are any navbar burgers
   if ($navbarBurgers.length > 0) {
 
     // Add a click event on each of them
     $navbarBurgers.forEach( el => {
       el.addEventListener('click', () => {
 
         // Get the target from the "data-target" attribute
         const target = el.dataset.target;
         const $target = document.getElementById(target);
 
         // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
         el.classList.toggle('is-active');
         $target.classList.toggle('is-active');
 
       });
     });
   }
 }
 
 render() {
   return (
  
    <nav className="navbar">
    <div className="container">
        <div className="navbar-brand">
            <div className="navbar-item"> <a className="brand" href="/">
                    <div className="brand-icon">
                    <Link to="/" >
                       <img src={logo} alt="TRU Open Source Club" tooltip="TRU Open Source" role="presentation" />
                    </Link>
                    
                    </div>
                    <div className="brand-content">
                        <div className="brand-title">TRU Open Source</div>
                        <div className="brand-subtitle">Spreading OpenSource love</div>
                    </div>
                </a> </div>
            <div className="navbar-burger burger" data-target="mainNav"> <span></span><span></span><span></span>
            </div>
        </div>
        <div className="navbar-menu" id="mainNav" data-target="mainNav">
            <div className="navbar-end"> 
            <Link className="navbar-item" to="/">
            Home
            </Link>
            <Link className="navbar-item" to="/blog">
          Updates
        </Link>
            
        <Link className="navbar-item" to="/events">
          Events
        </Link>
        <Link className="navbar-item" to="/about">
          About
        </Link>
        <Link className="navbar-item" to="/contact">
          Contact
        </Link>
        
            <div className="navbar-item"><a href="/" className="app-store-badge" target="_blank"> <img src="" alt="" role="presentation" /></a>
                </div>
            </div>
        </div>
    </div>
    
</nav>


  )}
}

export default Navbar
