import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types'
import messenger from '../img/Social/messenger.svg'
import facebook from '../img/Social/facebook.svg'
import instagram from '../img/Social/instagram.svg'
import twitter from '../img/Social/twitter.svg'
import github from '../img/Social/github-icon.svg'
import apple from '../img/logo/appstore.svg'
import logo from '../img/logo/logo.png'



const Footer = ({ children }) => (
    <StaticQuery
    query={graphql`
      query FooterQuery {
          site {
            siteMetadata {
              title,
              description,
              tagline,
            }
          }
        }
    `}
    render={data => (

<footer className="app-footer section is-medium">
            <div className="container">
                <div className="columns">
                    <div className="column is-5 is-4-widescreen">
                        <div className="summary"> <a className="brand" href="/">
                                <div className="brand-icon"> <img alt="TRU Open Source " role="presentation" src={logo} />
                                </div>
                                <div className="brand-content">
                                    <div className="brand-title"> {data.site.siteMetadata.title}</div>
                                    <div className="brand-subtitle">{data.site.siteMetadata.tagline}</div>
                                </div>
                            </a>
                            <hr className="spacer is-1" />
                            <p> {data.site.siteMetadata.description} </p>
                           <hr className="spacer is-1" /><a href=""
                                className="app-store-badge" target="_blank"> <img role="presentation" src="" /></a>
                            <hr className="spacer is-1" /><a href="/" target="_blank"> {(new Date().getFullYear())} &copy;&nbsp;{data.site.siteMetadata.title}</a>
                        </div>
                    </div>
                    <div className="column is-7 is-6-widescreen is-offset-2-widescreen">
                        <div className="columns">
                            <div className="column is-4">
                                <div className="menu">
                                    <p className="menu-label"> Quick Links</p>
                                    <ul className="menu-list">
                                        <li> <a href="/">Home</a> </li>
                                        <li> <a href="/about">About us</a>
                                        </li>
                                        <li> <a href="/meetups">Meetups</a> </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="menu">
                                    <p className="menu-label">Open Source</p>
                                    <ul className="menu-list">
                                        <li> <a href="/blog">Blog</a> </li>
                                        <li> <a href="/faq">FAQ</a> </li>
                                        <li> <a href="/coc">Code Of Conduct</a> </li>
                                        <li> <a href="/events">Events</a> </li>
                                        <li> <a href="/privacy">Privacy</a> </li>
                                
                                    </ul>
                                </div>{ children }
                            </div>
                            <div className="column is-4">
                                <div className="menu">
                                    <p className="menu-label"> Connect with us </p>
                                    <ul className="menu-list">
                                        <li><a href="https://facebook.com/TRUOpenSource" target="_blank"> <img role="presentation"
                                                    src={facebook} />Facebook</a>
                                        </li>
                                        <li><a href="https://m.me/TRUOpenSource" target="_blank"> <img role="presentation"
                                                    src={messenger} />Messenger</a>
                                        </li>
                                        <li><a href="https://www.instagram.com/TRUOpenSource" target="_blank"> <img role="presentation"
                                                    src={instagram} />Instagram</a>
                                        </li>
                                        <li><a href="https://twitter.com/TRUOpenSource" target="_blank"> <img role="presentation"
                                                    src={twitter} />Twitter</a>
                                        </li>
                                        <li><a href="https://github.com/TRUOpenSource" target="_blank"> <img role="presentation"
                                                    src={github} />Github</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
)}
/>
)
        Footer.propTypes = {

           FooterContent : PropTypes.string,
        }
            
        
        export default Footer