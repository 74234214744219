import React from 'react'
import PropTypes from 'prop-types'


const TopNotice = ({ Msgcontent, ButtonTextFull, ButtonTextSmall, MsgLink }) => (
    <div className="message is-medium is-success is-announcement">
    <div className="message-header is-justified-center">
        <p>{Msgcontent}</p>
        <hr className="spacer is-1 is-horizontal" /> <a className="button is-white" target="_blank" rel="noopener noreferrer" href={MsgLink}>{ButtonTextFull}
            <span className="is-hidden-mobile">&nbsp;{ButtonTextSmall}</span> </a>
    </div>
</div>
)

TopNotice.propTypes = {
  Msgcontent: PropTypes.string,
  ButtonTextFull: PropTypes.string,
  ButtonTextSmall: PropTypes.string,
  MsgLink: PropTypes.string,

}

TopNotice.propTypes = TopNotice.propTypes

export default TopNotice
