import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"

import Navbar from '../components/Navbar'
import './app.css'
import TopNotice from './TopNotice';
import Footer from './Footer';

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
          site {
            siteMetadata {
              title,
              description,
              tagline,
            }
          }
        }
    `}
    render={data => (
      <div>
        <Helmet>
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
          <meta name="description" content={data.site.siteMetadata.description} />
          
          <link rel="apple-touch-icon" sizes="180x180" href="/" />
	        <link rel="icon" type="image/png" href="/" sizes="32x32" />
	        <link rel="icon" type="image/png" href="/" sizes="16x16" />
	
	        <link rel="mask-icon" href="" color="#ff4400" />
	        <meta name="theme-color" content="#fff" />

	        <meta property="og:type" content="business.business" />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:url" content="/" />
          <meta property="og:image" content="/" />
        </Helmet>
        <div className="app-container">
        <TopNotice 
        Msgcontent="We are looking for enthusiastic minds to join our team, are you one of them ? "
        ButtonTextFull="📝 Apply Now"
        ButtonTextSmall=""
        MsgLink="http://bit.ly/ApplyOpenSource"
         />
        <header className="app-header">
        
        <Navbar></Navbar>
        </header>

        <main className="app-content">
        {children}
        </main>
        <Footer></Footer>
        </div>
      </div>
    )}
  />
)

export default TemplateWrapper
